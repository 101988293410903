<template>
  <v-card>
    <v-card-title>
      {{ $t('AddMember') }}
    </v-card-title>

    <v-card-text>
      <v-form
        ref="addMemberForm"
        @submit.prevent="handleSubmitForm"
      >
        <span>{{ $t('TypeMember') }}?</span>
        <v-radio-group
          v-model="hasAccount"
          class="mt-1"
        >
          <v-radio
            :label="$t('WithPhone')"
            :value="true"
          ></v-radio>
          <v-radio
            :label="$t('WithoutPhone')"
            :value="false"
          ></v-radio>
        </v-radio-group>

        <span>{{ $t('ChildHasAccount') }}</span>
        <v-radio-group
          v-model="memberType"
          class="mt-1"
        >
          <v-radio
            v-if="hasAccount"
            :label="$t('Parent')"
            :value="1"
          ></v-radio>
          <v-radio
            :label="$t('Child')"
            :value="2"
          ></v-radio>
        </v-radio-group>

        <template v-if="!hasAccount">
          <span>{{ $t('ChildInformation') }}</span>
          <v-text-field
            v-model="fullname"
            dense
            outlined
            :rules="[validators.required]"
            :placeholder="$t('Fullname')"
            hide-details="auto"
            class="mt-1 mb-3"
            validate-on-blur
          ></v-text-field>
          <v-select
            v-model="gender"
            dense
            outlined
            :label="$t('Gender')"
            :placeholder="$t('Gender')"
            :items="[
              {text: $t('Male'), value: 1},
              {text: $t('Female'), value: 2},
              {text: $t('Other'), value: 0}
            ]"
            :rules="[validators.required]"
            hide-details="auto"
            class="mb-3"
            validate-on-blur
          ></v-select>
          <v-autocomplete
            v-model.number="yearOfBirth"
            dense
            outlined
            :items="yearsOfBirth"
            :placeholder="$t('YearOfBirth')"
            :rules="[validators.required]"
            hide-details="auto"
            class="mt-1 mb-3"
            validate-on-blur
          ></v-autocomplete>
          <div class="mb-2">
            {{ $t('FaceImage') }}
          </div>
          <v-row>
            <v-col cols="6">
              <Upload
                v-model="faceImageUpload"
                :auto-sizing="true"
              />
            </v-col>
            <v-col cols="6">
              <Upload
                v-model="faceMaskImageUpload"
                :auto-sizing="true"
              />
            </v-col>
          </v-row>

          <v-btn
            class="mt-6"
            block
            color="primary"
            type="submit"
            :loading="loading"
            :disabled="loading"
          >
            {{ $t('AddMember') }}
          </v-btn>
        </template>

        <template v-if="!!hasAccount">
          <span>{{ $t('PhoneNumber') }}</span>
          <v-text-field
            v-model="phoneNumber"
            dense
            outlined
            type="tel"
            :placeholder="$t('PhoneNumber')"
            :rules="[validators.required, validators.phoneNumberValidator]"
            hide-details="auto"
            class="mt-1 mb-6"
          >
          </v-text-field>

          <v-btn
            block
            color="primary"
            type="submit"
            :loading="loading"
            :disabled="loading"
          >
            {{ $t('Invite') }}
          </v-btn>
        </template>

        <v-btn
          class="mt-4"
          block
          outlined
          color="primary"
          @click="$router.push({ name: 'user-family' })"
        >
          {{ $t('Cancel') }}
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Upload from '@/components/Upload/Upload.vue'
import FamilyService from '@/services/FamilyService'
import UploadService from '@/services/UploadService'
import store from '@/store'
import { useRouter } from '@core/utils'
import { phoneNumberValidator, required } from '@core/utils/validation'
import { ref, watch } from '@vue/composition-api'

export default {
  components: { Upload },
  setup() {
    const addMemberForm = ref(null)
    const { router } = useRouter()

    const memberType = ref(1)
    const phoneNumber = ref('')
    const fullname = ref('')
    const yearOfBirth = ref('')
    const hasAccount = ref(true)
    const gender = ref('')
    const loading = ref(false)
    const faceImageUpload = ref({})
    const faceMaskImageUpload = ref({})

    watch(hasAccount, val => {
      console.log(val)
      if (!val) {
        memberType.value = 2
      }
    })

    const handleSubmitForm = async () => {
      if (loading.value) return

      const isFormValid = addMemberForm.value.validate()

      if (!isFormValid) return
      loading.value = true

      if (memberType.value === 1 || (memberType.value === 2 && hasAccount.value)) {
        await FamilyService.invite({
          phone: phoneNumber.value,
          type_member: memberType.value,
        })
        loading.value = false
        router.push({ name: 'user-family' })
      } else {
        let faceImageId = ''
        let faceMaskImageId = ''

        await faceImageUpload.value
          .asyncBase64('image/jpeg', 0.8)
          .then(async base64 => {
            await UploadService.uploadFile({
              note: 'FaceImage',
              files: [base64],
              is_face: true,
            }).then(res => {
              console.log(res)
              faceImageId = res.data?.data?.files?.[0]?.id_file
            })
          })
          .catch(error => {
            console.error(error)
            store.commit('snackbar/show', {
              message: 'PleaseUpload',
              type: 'error',
            })
          })

        await faceMaskImageUpload.value
          .asyncBase64('image/jpeg', 0.8)
          .then(async base64 => {
            await UploadService.uploadFile({
              note: 'FaceMaskImage',
              files: [base64],
              is_face: true,
            }).then(res => {
              console.log(res)
              faceMaskImageId = res.data?.data?.files?.[0]?.id_file
            })
          })
          .catch(error => {
            console.error(error)
            store.commit('snackbar/show', {
              message: 'PleaseUpload',
              type: 'error',
            })
          })

        if (faceMaskImageId && faceImageId) {
          await FamilyService.addMember({
            full_name: fullname.value,
            gender: gender.value,
            year_of_birth: yearOfBirth.value,
            face_image: faceImageId,
            face_mask_image: faceMaskImageId,
          })
          router.push({ name: 'user-family' })
        }
        loading.value = false
      }
    }

    return {
      memberType,
      phoneNumber,
      fullname,
      hasAccount,
      yearOfBirth,
      gender,
      loading,
      faceImageUpload,
      faceMaskImageUpload,

      validators: {
        required,
        phoneNumberValidator,
      },
      handleSubmitForm,

      addMemberForm,
    }
  },
}
</script>
