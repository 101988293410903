/* eslint-disable class-methods-use-this */
import axios from '@/plugins/axios'

class FamilyService {
  get() {
    return axios.get('/v1/account/family')
  }

  create(data) {
    return axios.post('/v1/account/family', data)
  }

  invite(data) {
    return axios.post('/v1/account/family/invite', data)
  }

  update(data) {
    return axios.put('/v1/account/family', data)
  }

  listMember(id) {
    return axios.get(`/v1/account/family/${id}`)
  }

  addMember(data) {
    return axios.post('/v1/account/family/addMember', data)
  }

  memberConfirm(data) {
    return axios.post('/v1/account/family/join', data)
  }

  memberDeny(data) {
    return axios.post('/v1/account/family/deny', data)
  }

  removeMember(id) {
    return axios.delete(`/v1/account/family/member/${id}`)
  }

  leave() {
    return axios.delete('/v1/account/family/leave')
  }

  listInvites() {
    return axios.get('/v1/account/family/invitation')
  }
}

export default new FamilyService()
